<template>
  <div class='container-fluid p-4 orders-list-holder orders-colors'>
    <div class='row mb-2'>
      <div class='col-3'>
        <h3>Returns</h3>
      </div>
      <div class='col-9'>
        <order-filter
          v-for='(status, statusKey) in statuses' :key='statusKey'
          :status='statusKey'
          :statusName='status.name'
          :counter='status.counter'
          :selected='statusFilterIsSet(statusKey)'
          @filter-activated='setStatusFilter(statusKey)'
          :enabled='canFilter()'>
        </order-filter>
      </div>
      <b-col class='buttons-holder' v-show=canExport()>
        <b-button variant='success' @click='exportOrderReturns' :disabled='exportDisabled'>Export</b-button>
        <div id='info-export-orders'>
          <b-icon icon='info-circle' scale='1.5'></b-icon>
        </div>
        <b-tooltip
          id='info-export-orders-tooltip'
          target='info-export-orders'
          triggers='hover'
          placement='top'>
          Exportable returns list should be less than <strong> {{ this.limitExportableReturns }}</strong>.<br>
          Filtered Returns: <strong :class="searchCanBeExported ? 'exportable': 'not-exportable'"> {{ this.countReturns }} </strong><br>
          <strong v-show='!searchCanBeExported'> Please, refine your search</strong>
        </b-tooltip>
      </b-col>
    </div>
    <div class="row">
      <div class="col">
        <div class="filters_holder">
          <h6 v-b-toggle.collapse-2 class="m-1">Filters</h6>
          <b-collapse id="collapse-2">
            <b-row class="d-flex mb-4">
              <b-col cols="3">
                <b-form-group label="Return ID">
                  <b-form-input
                    v-model="filters.data.order_id"
                    type="number"
                    min=1
                    oninput="if(this.value.length>10) this.value=this.value.slice(0,10);">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="Patient ID">
                  <b-form-input
                    v-model="filters.data.patient_id"
                    type="number"
                    min=1
                    oninput="if(this.value.length>10) this.value=this.value.slice(0,10);">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3" class="colTime">
                <b-form-group label="Created from: (UTC time)">
                  <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                        left
                                        label="from..."
                                        :range="false"
                                        :no-button-now="true"
                                        :only-date="false"
                                        :min-date="minDateAvailable"
                                        :no-clear-button="true"
                                        v-model="filters.data.date_start"/>
                </b-form-group>
              </b-col>
              <b-col cols="3" class="colTime">
                <b-form-group label="Created to: (UTC time)">
                  <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                        left
                                        label="to..."
                                        :range="false"
                                        :no-button-now="true"
                                        :only-date="false"
                                        :min-date="minDateAvailable"
                                        :no-clear-button="true"
                                        v-model="filters.data.date_end"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="d-flex mb-4">
              <b-col cols="3">
                <b-form-group
                  id="input-group-status"
                  label="Status"
                  class="exception-label clients-exceptions-group">
                  <multiselect
                    v-model="filters.data.status"
                    tag-placeholder=""
                    placeholder=""
                    :options="statusOptions"
                    track-by="code"
                    label="label"
                    class="exception-multiselect"
                    :limit="3"
                    :multiple="true"
                    :taggable="false"></multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  id="input-group-clientName"
                  label="Client name"
                  class="exception-label clients-exceptions-group">
                  <multiselect
                    v-model="filters.data.client_names"
                    tag-placeholder=""
                    placeholder=""
                    :options="clientOptions"
                    track-by="code"
                    label="name"
                    class="exception-multiselect"
                    :multiple="true"
                    :limit="3"
                    :taggable="false"></multiselect>
                </b-form-group>
              </b-col>

              <b-col cols="3" class="colTime">
                <b-form-group label="Updated from: (UTC time)">
                  <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                        left
                                        label="from..."
                                        :range="false"
                                        :no-button-now="true"
                                        :only-date="false"
                                        :min-date="minDateAvailable"
                                        :no-clear-button="true"
                                        v-model="filters.data.updated_at_start"/>
                </b-form-group>
              </b-col>
              <b-col cols="3" class="colTime">
                <b-form-group label="Updated to: (UTC time)">
                  <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                        left
                                        label="to..."
                                        :range="false"
                                        :no-button-now="true"
                                        :only-date="false"
                                        :min-date="minDateAvailable"
                                        :no-clear-button="true"
                                        v-model="filters.data.updated_at_end"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="d-flex mb-4">
              <b-col cols="3">
                <b-form-group label="Account UUID">
                  <b-form-input
                    v-model="filters.data.account_uuid"
                    type="text"
                    maxLength=254>
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="2" class="d-flex align-items-center">
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="filters.data.returned_not_confirmed"
                    id="checkbox_returned_not_confirmed"
                    :options="checkboxOption">
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>

              <b-col cols="9" class="col_buttons ">
                <b-button variant="light" class="mr-3 float-right" @click="resetFilters">Reset</b-button>
                <b-button variant="primary" class="mr-3 float-right" @click="applyFilters">Apply</b-button>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <Returns ref='returns' :filters='filters' @ordersLoaded='searchCanBeExported'></Returns>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import translations from '@/translations';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { ORDER_TYPE, ORDER_STATUS } from '@/scripts/constants';
import userMixin from '@/mixins/user';
import orderHandler from '@/mixins/orderHandler';
import Returns from '../../../components/OrderManagement/returns/list.vue';
import OrderFilter from '../../../components/OrderManagement/Orders/OrderFilter.vue';

const returns = {
  statuses: {
    new: { code: ORDER_STATUS.New, label: translations.returns.status.new },
    request_sent: { code: ORDER_STATUS.RequestSent, label: translations.returns.status.request_sent },
    shipped: { code: ORDER_STATUS.Shipped, label: translations.returns.status.shipped },
    review: { code: ORDER_STATUS.Review, label: translations.returns.status.review },
    canceled: { code: ORDER_STATUS.Canceled, label: translations.returns.status.canceled },
    lost: { code: ORDER_STATUS.Lost, label: translations.returns.status.lost },
    returned: { code: ORDER_STATUS.Returned, label: translations.returns.status.returned },
    tracking_failed: { code: ORDER_STATUS.TrackingFailed, label: translations.returns.status.tracking_failed },
    failed_delivery: { code: ORDER_STATUS.FailedDelivery, label: translations.returns.status.failed_delivery },
    on_hold: { code: ORDER_STATUS.OnHold, label: translations.returns.status.on_hold },
  },
};

export default {
  name: 'orders-filters',
  components: {
    Multiselect,
    Returns,
    OrderFilter,
  },
  mixins: [ userMixin, orderHandler ],
  created() {
    this.statusOptions = [
      returns.statuses.new,
      returns.statuses.request_sent,
      returns.statuses.shipped,
      returns.statuses.returned,
      returns.statuses.tracking_failed,
      returns.statuses.on_hold,
      returns.statuses.failed_delivery,
    ];
    this.limitExportableReturns = 200000;
    this.loadClients();
    this.getOrdersOverview();
    this.$watch(
      () => this.$route.query,
      () => {
        this.$refs.returns.loadReturnsList(true);
      },
    );
  },
  data() {
    return {
      statusOptions: [],
      clientOptions: [],
      countReturns: 0,
      exportDisabled: true,
      checkboxOption: [
        { checked: false, value: true, text: 'Returned not confirmed' },
      ],
      filters: {
        isValid: true,
        data: {
          type: returns.type,
          date_start: this.$route.query.date_start,
          date_end: this.$route.query.date_end,
          updated_at_start: this.$route.query.updated_at_start,
          updated_at_end: this.$route.query.updated_at_end,
          order_id: this.$route.query.order_id,
          client_names: this.$route.query.client_names ? JSON.parse(this.$route.query.client_names) : [],
          patient_id: this.$route.query.patient_id,
          status: this.$route.query.status ? JSON.parse(this.$route.query.status) : [],
          account_uuid: this.$route.query.account_uuid,
          returned_not_confirmed: this.$route.query.returned_not_confirmed,
        },
      },
      statuses: {
        tracking_failed: {
          counter: 0,
          name: translations.returns.status.tracking_failed,
          selected: false,
        },
        failed_delivery: {
          counter: 0,
          name: translations.returns.status.failed_delivery,
          selected: false,
        },
      },
    };
  },
  computed: {
    minDateAvailable() {
      return moment().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss');
    },
    validateDateFilter() {
      const { data } = this.filters;
      return (data.date_start && moment(data.date_start).isValid())
        || (data.date_end && moment(data.date_end).isValid());
    },
    validateUpdateAtFilter() {
      const { data } = this.filters;
      return (data.updated_at_start && moment(data.updated_at_start).isValid())
        || (data.updated_at_end && moment(data.updated_at_end).isValid());
    },
  },
  methods: {
    applyFilters() {
      const { data } = this.filters;
      this.$router.replace({
        query: {
          order_id: data.order_id,
          date_start: data.date_start,
          date_end: data.date_end,
          updated_at_start: data.updated_at_start,
          updated_at_end: data.updated_at_end,
          client_names: JSON.stringify(data.client_names),
          patient_id: data.patient_id,
          status: JSON.stringify(data.status),
          account_uuid: data.account_uuid,
          returned_not_confirmed: data.returned_not_confirmed,
        },
      });
    },
    resetFilters() {
      this.clearFilters();
    },
    clearFilters() {
      Object.assign(this.$data.filters, {
        isValid: true,
        data: {
          date_start: null,
          date_end: null,
          updated_at_start: null,
          updated_at_end: null,
          order_id: null,
          client_names: [],
          patient_id: null,
          status: [],
          account_uuid: null,
          returned_not_confirmed: false,
        },
      });
      this.$router.replace({ query: { } });
    },
    async getOrdersOverview() {
      const statusOverview = await this.$store.dispatch(
        'OrderManagement/getOrdersOverview',
        { type: ORDER_TYPE.RETURN },
      );
      if (statusOverview) {
        statusOverview.forEach(elem => {
          switch (elem.status) {
            case 'tracking_failed':
              this.statuses.tracking_failed.counter = elem.total;
              break;
            case 'failed_delivery':
              this.statuses.failed_delivery.counter = elem.total;
              break;
            default:
              break;
          }
        });
      }
    },
    setStatusFilter(status) {
      this.clearFilters();
      this.filters.isValid = true;

      switch (status) {
        case 'tracking_failed':
          this.filters.data.status.push(returns.statuses.tracking_failed);
          break;
        case 'failed_delivery':
          this.filters.data.status.push(returns.statuses.failed_delivery);
          break;
        default:
          break;
      }

      this.applyFilters();
    },
    statusFilterIsSet(status) {
      if (!this.$route.query.status) {
        return false;
      }

      const statusSet = JSON.parse(this.$route.query.status);
      // Check if any status is set
      if (!statusSet.length) {
        return false;
      }
      return statusSet.some(st => st.code === status);
    },
    canFilter() {
      if (this.validateDateFilter || this.filters.data.order_id || this.filters.data.patient_id
        || this.filters.data.status || this.filters.data.client_names || this.validateUpdateAtFilter
        || this.filters.data.account_uuid) {
        this.filters.isValid = true;
      } else this.filters.isValid = true;

      return this.filters.isValid;
    },
    getClients() {
      return axios.get('v1/clients')
        .then(res => res.data)
        .catch(err => {
          console.error(err);
          return err;
        });
    },
    async loadClients() {
      try {
        const res = await this.getClients();
        res.payload.forEach(p => {
          this.clientOptions.push({
            id: p.id,
            code: p.name,
            name: p.display_name,
          });
        });
      } catch (err) {
        this.$noty.error('Unable to load Clients');
      }
    },
    exportOrderReturns() {
      this.$refs.returns.exportOrders();
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/OrderManagement.scss';
</style>
