<template>
  <b-card class="mb-4 border-0 font_avenir card_orderslist">
    <b-spinner v-if="loadingPage"></b-spinner>
    <div class="holder_table">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Created at</th>
          <th scope="col">Return ID</th>
          <th scope="col">Patient ID</th>
          <th scope="col">Patient Email</th>
          <th scope="col">Client Name</th>
          <th scope="col">Trigger</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="r in returns" :key="r.order_id">
          <td>{{ r.created_at }}</td>
          <td><b-link :to="`/orders/${r.order_id}`">{{ r.order_id }}</b-link></td>
          <td>{{ r.patient_id }}</td>
          <td>{{ r.patient_email }}</td>
          <td>{{ r.client_name }}</td>
          <td>{{ r.trigger_reason }}</td>
          <td class="div_clex_name orders-colors">
            <span :class="[r.status, 'clex_name']">{{ translations.status[r.status] || r.status }}</span>
          </td>
          <td style="width: 11%" class="align-middle text-center">
              <div class="btn-group dropleft">
                <button
                    variant="light"
                    type="button"
                    class="btn btn-light"
                    data-toggle="dropdown"
                    aria-expanded="false">
                  <feather type="more-horizontal"></feather>
                </button>
                <div class="dropdown-menu">
                  <orderAllowedActions
                    :order="r">
                  </orderAllowedActions>
                </div>
              </div>
            </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loadingPage"
                      @change="changePage"
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </b-card>
</template>

<script>
import translations from '@/translations';
import orderHandler from '@/mixins/orderHandler';
import { ORDER_STATUS, ORDER_TYPE } from '@/scripts/constants';
import orderAllowedActions from '../Orders/OrderAllowedActions.vue';

const queryOperators = {
  equal: '=',
  like: 'LIKE',
  in: 'in',
  greaterThanOrEqualTo: '>=',
  lessThantOrEqualTo: '<=',
};

export default {
  name: 'returns-list',
  components: {
    orderAllowedActions,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({
        isValid: false,
        data: {
          date_start: null,
          date_end: null,
          updated_at_start: null,
          updated_at_end: null,
          order_id: null,
          client_names: [],
          patient_id: null,
          status: [],
        },
      }),
    },
  },
  mixins: [ orderHandler ],
  data() {
    return {
      returns: [],
      loadingPage: false,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      translations: translations.returns,
      statuses: {
        on_hold: { name: ORDER_STATUS.OnHold, translation: translations.returns.status.on_hold },
        request_sent: { name: ORDER_STATUS.RequestSent, translation: translations.returns.status.request_sent },
        returned: { name: ORDER_STATUS.Returned, translation: translations.returns.status.returned },
      },
      selectedOrder: null,
      minPickupDate: '',
      maxPickupDate: '',
    };
  },
  async beforeMount() {
    await this.loadReturnsList();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.loadReturnsList();
    },
    buildFilters() {
      const values = [];
      const { data } = this.filters;

      values.push({ field: 'type', value: ORDER_TYPE.RETURN, operator: queryOperators.equal });

      if (!this.filters.isValid || !data) {
        return values;
      }

      if (data.order_id) values.push({ field: 'id', value: data.order_id, operator: queryOperators.equal });

      if (data.client_names && data.client_names.length > 0) {
        const selectedClientName = data.client_names.map(item => item.name);
        values.push({ field: 'client_name', value: selectedClientName, operator: queryOperators.in });
      }

      if (data.date_start) {
        values.push({ field: 'created_at', value: data.date_start, operator: queryOperators.greaterThanOrEqualTo });
      }

      if (data.date_end) {
        values.push({ field: 'created_at', value: data.date_end, operator: queryOperators.lessThantOrEqualTo });
      }

      if (data.patient_id) values.push({ field: 'patient_id', value: data.patient_id, operator: queryOperators.equal });

      if (data.status && data.status.length > 0) {
        const selectedStatus = data.status.map(item => item.code);
        values.push({ field: 'status', value: selectedStatus, operator: queryOperators.in });
      }

      if (data.account_uuid) values.push({ field: 'account_uuid', value: data.account_uuid, operator: queryOperators.equal });


      if (data.updated_at_start) {
        values.push({
          field: 'updated_at',
          value: data.updated_at_start,
          operator: queryOperators.greaterThanOrEqualTo,
        });
      }

      if (data.updated_at_end) {
        values.push({ field: 'updated_at', value: data.updated_at_end, operator: queryOperators.lessThantOrEqualTo });
      }

      if (data.returned_not_confirmed) values.push({ field: 'tag', value: 'returned_not_confirmed', operator: queryOperators.equal });


      return values;
    },
    async loadReturnsList(resetPagination) {
      this.loadingPage = true;
      if (resetPagination) {
        this.currentPage = 1;
      }

      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;

      try {
        const queries = this.buildFilters();

        const response = await this.$store.dispatch('OrderManagement/getOrders', { queries, offset, limit });

        this.returns = response.orders;
        this.total = response.total;
        this.$emit('ordersLoaded', this.total);
      } catch (err) {
        this.$noty.error('Unable to load returns: ', err);
      } finally {
        this.loadingPage = false;
      }
    },
    async exportOrders() {
      try {
        const queries = this.buildFilters();
        const response = await this.$store.dispatch('OrderManagement/exportOrders', { queries });

        const url = window.URL.createObjectURL(new Blob([ response ]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'returns.csv');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        this.$noty.error('Unable to download returns CSV: ', err.message);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/OrderManagement.scss';
</style>
